/**
 *FICHERO DE CONFIGURACION DE VARIABLES DE ENTORNO PARA VIGO STG
 */
export const environment_data = {
    production: false,
    APIURL: 'https://apisubasta-apvigo.saecdata.net',
    MQTT_SERVER_URL: 'mqtt.saecdata.net',
    MQTT_PROTOCOL: "wss",
    MQTT_USER: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IjQ1NTYiLCJzdWIiOiI0NTU2IiwiZXhwIjoyNTM0MDIzMDA3OTksInR5cGUiOiJhY2Nlc3MifQ.CnoXVkSpwTpnHjYzyRijahB6VpFk-996IXtoCj9o7U0',
    MQTT_PASSWD: '12345678',
    MQTT_PORT: 8085,
    DEFAULT_TIPO_PANTALLA: "2", //tipo de pantalla por defecto // Valores esperados del 1 al 7
};